import React, { Component, useCallback, useEffect, useRef } from 'react';

import * as L from "leaflet";
import MapHelper from "./../../../helpers/MapHelper";
import ZoomControl from "./ui/ZoomControl";
import MapTypeControl from "./ui/MapTypeControl";
import ShapesControl from "./ui/ShapesControl";
import OnMapInfoPanel from "./ui/OnMapInfoPanel";
import MapWithMeasurement from './ui/MapWithMeasurement';


import _ from 'lodash';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { useMap } from "../../../providers/MapProvider";
import ShapeStyling from "../shapes/ShapeStyling";
import {useApi} from "../../../providers/ApiProvider";
import PropertiesRequests from "../../../requests/PropertiesRequests";
import MapSearchControlGetAddress from "./ui/MapSearchControlGetAddress";
import ProjectsRequests from "../../../requests/ProjectsRequests";
import Loading from "../../ui/Loading";
import ImportExportControl from "./ui/ImportExportControl";
import MapInfoPanel from "./ui/MapInfoPanel";
import ShapePanelControl from "../shapes/ShapePanelControl";
import {useParams} from "react-router-dom";
import ShapesListPanel from "../shapes/ShapesListPanel";
import ShapesRequests from "../../../requests/ShapesRequests";
import {useNotifications} from "../../../providers/NotificationsProvider";
import UnitsToggle from "./ui/UnitsToggle";
import EmailReportModal from "./ui/EmailReportModal";
import {Tooltip} from "react-tooltip";
import SimpleModal from "../../ui/SimpleModal";
import PropertiesModel from "../../../models/PropertiesModel";
import ShapesModel from "../../../models/ShapesModel";


export default function Map({ project, canEdit = false, onProjectChange, onMapInitialized }) {
    const {projectId} = useParams();
    const mapContext = useMap();
    const apiContext = useApi();
    const notifications = useNotifications();
    const shapesRequests = new ShapesRequests(apiContext.api);
    const propertiesRequests = new PropertiesRequests(apiContext.api);
    const projectsRequests = new ProjectsRequests(apiContext.api);

    const shapesModelRef = useRef(new ShapesModel(projectsRequests));

    const [projectInfo, setProjectInfo] = React.useState({ title: project.title ? project.title : '', description: project.description ? project.description : '' });
    const [curCenter, setCurCenter] = React.useState(MapHelper.getDefaultCenter());
    const [map, setMap] = React.useState(null);
    const [mapLayers, setMapLayers] = React.useState({});
    const [allShapesShown, setAllShapesShown] = React.useState(false);
    const [infoPanelsMinified, setInfoPanelsMinified] = React.useState(false);
    const [locations, setLocations] = React.useState({ positive: [], negative: [], stats: {total: 0, positive: 0, negative: 0} });
    const locationsLoading = React.useRef([]);
    const [cursorPosition, setCursorPosition] = React.useState({ lat: 0, lng: 0 });
    const [rulerActive, setRulerActive] = React.useState(0);
    const [emailReportModalShown, setEmailReportModalShown] = React.useState(false);
    const [purchaseOrderModalShown, setPurchaseOrderModalShown] = React.useState(false);
    const [purchaseOrder, setPurchaseOrder] = React.useState('');
    const [dataVersion, setDataVersion] = React.useState('');

    let baseSelectedMapType = MapHelper.defaultMapType,
        baseLocationFilters = [1, 2];
    if (project && project.versionParameters) {
        if (project.versionParameters.map_type) {
            baseSelectedMapType = project.versionParameters.map_type;
        }
        if (project.versionParameters.location_filters) {
            baseLocationFilters = project.versionParameters.location_filters;
        }
    }
    const [selectedMapType, setSelectedMapType] = React.useState(baseSelectedMapType);
    const [locationFilters, setLocationFilters] = React.useState(baseLocationFilters);

    let baseShapes = {}
    if (project && project.data && project.data.shapes) {
        baseShapes = project.data.shapes;
    }
    const [shapes, setShapes] = React.useState(baseShapes); // dry shapes

    const mapRef = React.useRef(null);
    const mapSearchedLocationRef = React.useRef(null);


    useEffect(() => {
        if (mapContext.isInitialized) {
            window.location.reload();
        }
    }, []);


    useEffect(() => {
        if (!mapContext.isInitialized || !map) {
            let defaultMapType = '';
            if (project.versionParameters && project.versionParameters.map_type) {
                defaultMapType = project.versionParameters.map_type;
            }
            const mapElements = MapHelper.initMap(mapRef.current, curCenter, defaultMapType);

            setMapLayers(mapElements.layers);

            if (project && project.data && project.data.shapes) {
                shapesModelRef.current.update(project.data.shapes, project.data.locations);

                if (shapesModelRef.current.boundsValid) {
                    mapElements.map.fitBounds(shapesModelRef.current.bounds, {paddingTopLeft: [64, 160], paddingBottomRight: [64, 64]});
                }
            }

            mapContext.init(mapElements.map);
        }

        if (project.title !== projectInfo.title || project.description !== projectInfo.description) {
            setProjectInfo({ title: project.title, description: project.description });
        }

        if (project.versionParameters && project.versionParameters.map_type && project.versionParameters.map_type !== selectedMapType) {
            setSelectedMapType(project.versionParameters.map_type);
        }

        if (project.versionParameters && project.versionParameters.location_filters && project.versionParameters.location_filters !== locationFilters) {
            setLocationFilters(project.versionParameters.location_filters);
        }
    }, [project]);


    useEffect(() => {
        if (mapContext.isInitialized) {
            setMap(mapContext.map);

            onMapInitialized();
            if (shapesModelRef.current.boundsValid) {
                setShapes(shapesModelRef.current.dryShapes);
            }
        }
    }, [mapContext.isInitialized]);


    useEffect(() => {
        if (map && mapLayers[selectedMapType]) {
            mapLayers[selectedMapType].addTo(map);
            if (['OSMStandard', 'esriWorldImagery', 'esriTopographic'].includes(selectedMapType)) {
                if (map.getZoom() > 19) {
                    map.setZoom(19);
                }
                map.setMaxZoom(19);
            } else {
                map.setMaxZoom(MapHelper.defaultMaxZoom);
            }

            Object.keys(mapLayers).forEach((key) => {
                if (key !== selectedMapType) {
                    map.removeLayer(mapLayers[key]);
                }
            });

            map.on('mousemove', onMouseMove);
        }
    }, [map, mapLayers, selectedMapType]);


    useEffect(() => {
        refreshLocations().then((newLocations) => {
            shapesModelRef.current.updateLocations(newLocations ? newLocations.stats : null);

            onProjectChange({
                shapes: shapesModelRef.current.getDataForProjectUpdate()
            }, false);
        });
    }, [locationFilters]);


    useEffect(() => {
        if (shapes === null) {return;}
        shapesModelRef.current.update(shapes);

        const noChangesToShapes = _.isEqual(shapesModelRef.current.dryShapes, project.data.shapes);
        if (noChangesToShapes) {return;}

        refreshLocations().then((newLocations) => {
            shapesModelRef.current.update(shapes, newLocations ? newLocations.stats : null);
            notifyBadShapes();

            onProjectChange({
                shapes: shapesModelRef.current.getDataForProjectUpdate()
            });
        });
    }, [shapes]);


    const notifyBadShapes = () => {
        if (shapesModelRef.current.oversizeShapes.length > 0) {
            let text = 'Some shapes are too large and cannot be processed: ';
            let shapesArr = [];
            shapesModelRef.current.oversizeShapes.forEach((shape) => {
                let shapeTitle = shape.geoJSON && shape.geoJSON.properties && shape.geoJSON.properties.title ? shape.geoJSON.properties.title : shape.id;
                shapesArr.push('"'+shapeTitle+'"');
            });
            text += shapesArr.join(', ');

            notifications.notify(text, 'warning');
        }
    }


    const onMapSearchControlChange = (lat, lng, additional) => {
        map.setView([lat, lng], MapHelper.defaultZoomLevelDetailed);

        if (!mapSearchedLocationRef.current) {
            mapSearchedLocationRef.current = L.marker([lat, lng]).addTo(map);
        } else {
            mapSearchedLocationRef.current.setLatLng([lat, lng]);
        }

        if (additional && additional.formatted_address) {
            let popupContent = '<div class="p-2">' + additional.formatted_address.filter((item) => item.length > 0).join('<br>') + '</div>';
            mapSearchedLocationRef.current.bindPopup(popupContent).openPopup();
        }
    }


    const onMapTypeControlChange = (layerName) => {
        const crsChanged = ['osRoadLayer', 'osLeisureLayer'].includes(layerName) && !['osRoadLayer', 'osLeisureLayer'].includes(selectedMapType) || !['osRoadLayer', 'osLeisureLayer'].includes(layerName) && ['osRoadLayer', 'osLeisureLayer'].includes(selectedMapType);

        setSelectedMapType(layerName);

        if (canEdit) {
            onProjectChange({ map_type: layerName }).then((response) => {
                if (crsChanged) {
                    window.location.reload();
                } else {
                    let zoom = map.getZoom();

                    if (layerName === 'osRoadLayer') {
                        if (zoom > 13) {
                            map.setZoom(13);
                        } else if (zoom < 10) {
                            map.setZoom(10);
                        }
                    }

                    if (layerName === 'osLeisureLayer') {
                        if (zoom > 9) {
                            map.setZoom(9);
                        }
                    }
                }
            });
        }
    }


    const refreshLocations = () => {
        if (locationsLoading.current.includes('locations')) {return new Promise((resolve) => {resolve();});}
        locationsLoading.current.push('locations');

        return PropertiesModel.refreshLocations(shapesModelRef.current.dryShapes, projectId, propertiesRequests).then((results) => {
            setDataVersion(results.dataVersion);

            const newLocations = PropertiesModel.processProperties(shapesModelRef.current.dryShapes, results.properties, locationFilters);
            setLocations(newLocations);
            //console.log('Locations refreshed', newLocations);
            locationsLoading.current = locationsLoading.current.filter((item) => item !== 'locations');

            return newLocations;
        });
    }


    const onAddShapeClick = (type) => {
        let newShapes = {...shapes};
        Object.keys(newShapes).forEach((key) => {
            newShapes[key].active = false;
            if (!newShapes[key].geoJSON) {
                delete newShapes[key];
            }
        });

        if (type) {
            let shape = {
                id: ShapeStyling.generateRandomId(),
                type: type,
                active: true,
            };
            newShapes[shape.id] = shape;
        }

        setShapes(newShapes);
    }


    const onShapeActiveChange = (id, active, geoJSON, layerData, shapesChangingActive = false) => {
        if (active && (shapesChangingActive === false)) {
            let newShapes = {...shapes};

            Object.keys(newShapes).forEach((key) => {
                if (newShapes[key].active === true) {
                    newShapes[key].active = false;
                }
            });

            newShapes[id].active = true;

            setShapes(newShapes);
        } else if (shapesChangingActive === false) {
            let newShapes = {...shapes};
            newShapes[id].active = false;
            if (geoJSON) {
                newShapes[id].geoJSON = geoJSON;
                newShapes[id].bounds = L.geoJSON(geoJSON).getBounds();
            }
            if (layerData) {newShapes[id].layerData = layerData;}

            setShapes(newShapes);
        } else {
            let shapesEdited = false, coordinatesChanged = false;
            if (geoJSON && !_.isEqual(shapes[id].geoJSON, geoJSON)) {
                shapesEdited = true;

                if (shapes[id].geoJSON && !_.isEqual(shapes[id].geoJSON.geometry, geoJSON.geometry) || !shapes[id].geoJSON) {
                    coordinatesChanged = true;
                }
            }

            let newShapes = {...shapes};
            newShapes[id].active = true;
            if (geoJSON) {
                newShapes[id].geoJSON = geoJSON;
                newShapes[id].bounds = L.geoJSON(geoJSON).getBounds();
            }
            if (layerData) {newShapes[id].layerData = layerData;}

            setShapes(newShapes);
        }
    }


    const onShapeDelete = (id) => {
        let newShapes = {...shapes};

        delete newShapes[id];

        setShapes(newShapes);
    }


    const onShapeToggle = (id) => {
        let newShapes = {...shapes};

        if (newShapes[id] && newShapes[id].geoJSON && newShapes[id].geoJSON.properties) {
            if (newShapes[id].geoJSON.properties.union === false) {
                newShapes[id].geoJSON.properties.union = true;
            } else {
                newShapes[id].geoJSON.properties.union = false;
            }
        }

        setShapes(newShapes);
    }


    const onShapeToggleVisible = (id) => {
        let newShapes = {...shapes};

        if (newShapes[id] && newShapes[id].geoJSON && newShapes[id].geoJSON.properties) {
            if (newShapes[id].geoJSON.properties.visible === false) {
                newShapes[id].geoJSON.properties.visible = true;
            } else {
                newShapes[id].geoJSON.properties.visible = false;
            }
        } else {
            newShapes[id].geoJSON.properties = { visible: false };
        }

        setShapes(newShapes);
    }


    const onShapeActivate = (id) => {
        onShapeActiveChange(id, true);

        if (shapes[id].bounds) {
            let newBounds = L.latLngBounds(shapes[id].bounds._southWest, shapes[id].bounds._northEast);
            if (newBounds.isValid()) {
                mapContext.map.fitBounds(newBounds);
            }
        }
    }


    const onMouseMove = (e) => {
        setCursorPosition(e.latlng);
    };


    const onShapesImported = (importedShapes, bounds) => {
        setShapes(importedShapes);

        if (bounds) {
            map.fitBounds(bounds);
        }
    }


    const onImportFromFiles = (fileId) => {
        shapesRequests.view(fileId).then((response) => {
            if (response !== false && response.data) {
                let newShapeDraft = {
                    id: ShapeStyling.generateRandomId(),
                    type: MapHelper.detectShapeType(response.data),
                    geoJSON: response.data,
                    bounds: L.geoJSON(response.data).getBounds(),
                    layerData: {
                        imported: true
                    },
                    active: false,
                };

                let newShapes = {...shapes};
                newShapes[newShapeDraft.id] = newShapeDraft;
                setShapes(newShapes);
                notifications.notify('File imported successfully', 'success');

                map.fitBounds(newShapeDraft.bounds);
            }
        });
    }


    const onRulerActive = (rulerAct) => {
        setRulerActive(rulerAct);
    };


    const showEmailReportModal = () => {
        if (projectInfo.title === 'Untitled Project') {
            notifications.notify('Please set a title for the project before finalising', 'warning');
            return;
        }

        setEmailReportModalShown(true);
    }


    const getStatusLabel = () => {
        if (!project || !project.status) {return '';}

        switch (project.status) {
            case '00_in_progress':
                return 'In Progress';
            case '10_processed':
                return 'Processed';
            case '20_archived':
                return 'Archived';
            default:
                return 'Canceled'
        }
    }


    const finaliseProject = () => {
        if (projectInfo.title === 'Untitled Project') {
            notifications.notify('Please set a title for the project before finalising', 'warning');
            return;
        }
        setPurchaseOrder('');
        setPurchaseOrderModalShown(true);
    }


    const finalizeConfirmedProject = () => {
        let newParams = project.params ? {... project.params} : {};
        newParams.purchase_order = purchaseOrder;

        onProjectChange({ status: '10_processed', params: newParams }).then((response) => {
            if (response !== false) {
                requestReportOnFinalisedProject().then((response) => {
                    //if (response !== false) {
                        notifications.notify('Project finalised successfully', 'success');
                        window.location.reload();
                    //}
                });
            }
        });
    }


    const requestReportOnFinalisedProject = () => {
        let data = {
            totalRecords: locations && locations.stats && locations.stats.positive ? locations.stats.positive : 0,
            catIds: locations && locations.positive ? locations.positive : [],
            reportType: 2
        };

        return projectsRequests.requestReport(projectId, data);
    }


    const setFilters = (filter) => {
        let filterCode = [];
        switch (filter) {
            case 'house': filterCode = [1]; break;
            case 'business': filterCode = [2]; break;
            case 'not-yet-built': filterCode = [3, 4]; break;
        }

        let newFilters = [...locationFilters];
        filterCode.forEach((code) => {
            if (code === 3 || code === 4) {return;}

            if (newFilters.includes(code)) {
                newFilters = newFilters.filter((item) => item !== code);
            } else {
                newFilters.push(code);
            }
        });
        if (filterCode.includes(3) || filterCode.includes(4)) {
            if (newFilters.includes(3) || newFilters.includes(4)) {
                newFilters = newFilters.filter((item) => item !== 3 && item !== 4);
            } else {
                newFilters.push(3);
                newFilters.push(4);
            }
        }

        if (canEdit) {
            onProjectChange({ location_filters: newFilters });
        }

        setLocationFilters(newFilters);
    }


    const onBringShapeToBack = (id) => {
        let newShapes = {...shapes};

        let newShapesKeys = Object.keys(newShapes).filter((key) => key !== id);
        newShapesKeys.unshift(id);

        let newShapesOrdered = {};
        newShapesKeys.forEach((key) => {
            newShapesOrdered[key] = newShapes[key];
        });

        setShapes(newShapesOrdered);
    }
    const onBringShapeToFront = (id) => {
        let newShapes = {...shapes};

        let newShapesKeys = Object.keys(newShapes).filter((key) => key !== id);
        newShapesKeys.push(id);

        let newShapesOrdered = {};
        newShapesKeys.forEach((key) => {
            newShapesOrdered[key] = newShapes[key];
        });

        setShapes(newShapesOrdered);
    }


    const getDaysToDelete = () => {
        const TTL = 3 * 30;
        const created = new Date(project.created_at);

        const now = new Date();
        const diff = now - created;
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));

        const daysDiff = TTL - days;

        let str = daysDiff + ' day';

        if (daysDiff % 10 !== 1 || daysDiff % 100 === 11) {
            return str + 's';
        } else {
            return str;
        }
    }


    const getTooltipSplitByTypes = () => {
        let houses = 0,
            businesses = 0,
            notYetBuilt = 0;

        if (locations && locations.stats && locations.stats.byType) {
            houses = locations.stats.byType.house;
            businesses = locations.stats.byType.business;
            notYetBuilt = locations.stats.byType['not-yet-built'];
        }

        return "Households: "+houses+"<br/>Business: "+businesses+"<br/>Not yet Built: "+notYetBuilt;
    }


    return (
        <div className={'map-panel ' + (rulerActive ? 'ruler-active' : '')}>
            <div id="map-container" ref={mapRef}></div>

            <div className={'map-area map-top'}>
                <MapSearchControlGetAddress address={''} onChange={onMapSearchControlChange} />
            </div>

            {project && project.data && (
            <div className={'map-area project-info'}>
                <OnMapInfoPanel className={'style-warning'}>
                    <MapInfoPanel
                        project={project}
                        type={'title'}
                        onValueChange={(value) => {
                            let newProjectInfo = {...projectInfo};
                            newProjectInfo.title = value;
                            setProjectInfo(newProjectInfo);
                        }}
                    >
                        <h1 className="h5 m-0">
                            {projectInfo.title}
                            <div className={'fs-1 mt-1 position-relative ' + (project.status !== '00_in_progress' ? 'text-danger' : 'text-muted')}>
                                Status: {getStatusLabel()}
                                
                                {project.params && project.params.purchase_order ? (
                                    <span className="ms-2">PO: {project.params.purchase_order}</span>
                                ) : ''}

                                {locations.stats.positive > 0 ? (
                                <button onClick={() => {
                                        finaliseProject();
                                    }}
                                    className={'align-items-center border-0 btn d-flex position-absolute end-0 top-0 p-0' + ((project.status !== '00_in_progress' || !canEdit) ? ' d-none' : '')}>
                                    <i className={'ti fs-4 ti-send'}></i>
                                    <span className={'fs-2 ms-2'}>Finalise the project</span>
                                </button>
                                ) : ''}
                            </div>
                            <div className="fs-1 mt-1 text-muted">ID: {project.id}</div>
                            {(project.status === '00_in_progress') ? (
                            <div className="fs-1 mt-1 text-muted">Created on {MapHelper.UTCtoLocal(project.created_at)}{!canEdit ? '' : ' - Delete in ' + getDaysToDelete()}</div>
                            ) : (
                            <div className="fs-1 mt-1 text-muted">Data Version: {dataVersion}</div>
                            )}

                            <button onClick={() => {
                                showEmailReportModal();
                            }} className={'align-items-center border-0 btn d-flex float-start mt-2 p-0' + ((!['00_in_progress'].includes(project.status) || !canEdit) ? ' d-none' : '')}>
                                <i className={'ti fs-4 ti-mail'}></i>
                                <span className={'fs-2 ms-2'}>Email Report</span>
                            </button>

                            <button onClick={() => {
                                if (!infoPanelsMinified) {
                                    setAllShapesShown(false);
                                }
                                setInfoPanelsMinified(!infoPanelsMinified)
                            }} className="align-items-center border-0 btn d-flex float-end mt-2 p-0">
                                <i className={'ti fs-4 ' + (infoPanelsMinified ? 'ti-arrows-diagonal' : 'ti-arrows-diagonal-minimize-2')}></i>
                                <span className={'fs-2 ms-2'}>{infoPanelsMinified ? 'Maximize' : 'Minimize'}</span>
                            </button>

                            <button onClick={() => {
                                refreshLocations();
                                notifications.notify('Updating project information: counts may be changed!', 'warning');
                            }} className={'align-items-center border-0 btn d-flex float-end mt-2 me-3 p-0'+((project.status !== '00_in_progress' || !canEdit) ? ' d-none' : '')}>
                                <i className={'ti fs-4 ti-refresh'}></i>
                                <span className={'fs-2 ms-2'}>Refresh</span>
                            </button>
                        </h1>
                    </MapInfoPanel>
                </OnMapInfoPanel>
                {infoPanelsMinified === false && (<>
                    {projectInfo.description && (
                        <OnMapInfoPanel className={'style-warning'}>
                            <MapInfoPanel
                                project={project}
                                type={'description'}
                                onValueChange={(value) => {
                                    let newProjectInfo = {...projectInfo};
                                    newProjectInfo.description = value;
                                    setProjectInfo(newProjectInfo);
                                }}
                            >
                                <div>{projectInfo.description.split('\n').map((str, index, array) =>
                                    ((index === array.length - 1) || (!str.length)) ? str :
                                        <p key={index} className={index < (array.length - 1) ? 'mb-1' : ''}>{str}</p>
                                )}</div>
                            </MapInfoPanel>
                        </OnMapInfoPanel>
                    )}
                    <OnMapInfoPanel className={'style-warning'}>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="">
                                <div
                                    data-tooltip-html={getTooltipSplitByTypes()}
                                    data-tooltip-place="right"
                                    data-tooltip-id="location-type-tooltips"
                                >Total mailing count: {locations.stats.positive} / {locations.stats.total} <Loading show={!!locationsLoading.current.length}/></div>
                                <span className={'d-flex flex-column fs-2 fw-semibold mt-2 text-muted' + ((project.status !== '00_in_progress' || !canEdit) ? ' d-none' : '')}>
                                    <span>Filter properties by type:</span>

                                    <div className="d-flex gap-2">
                                        <button
                                            className={'align-items-center border-0 btn d-flex p-0'}
                                            onClick={() => setFilters('house')}

                                            data-tooltip-content="Residential buildings"
                                            data-tooltip-place="right"
                                            data-tooltip-id="location-type-tooltips"
                                        >
                                            <i className={'ti fs-4 ti-home ' + (locationFilters.includes(1) ? 'text-warning' : 'opacity-50')}></i>
                                        </button>

                                        <button
                                            className={'align-items-center border-0 btn d-flex p-0'}
                                            onClick={() => setFilters('business')}

                                            data-tooltip-content="Business buildings"
                                            data-tooltip-place="right"
                                            data-tooltip-id="location-type-tooltips"
                                        >
                                            <i className={'ti fs-4 ti-building-store ' + (locationFilters.includes(2) ? 'text-warning' : 'opacity-50')}></i>
                                        </button>

                                        <button
                                            className={'align-items-center border-0 btn d-flex p-0'}
                                            onClick={() => setFilters('not-yet-built')}

                                            data-tooltip-content="Not yet built buildings"
                                            data-tooltip-place="right"
                                            data-tooltip-id="location-type-tooltips"
                                        >
                                            <i className={'ti fs-4 ti-wall ' + ((locationFilters.includes(4) || locationFilters.includes(3)) ? 'text-warning' : 'opacity-50')}></i>
                                        </button>
                                    </div>
                                </span>

                                <Tooltip id="location-type-tooltips"/>
                            </div>
                            <div className="">
                                {Object.keys(shapes).length} Shape(s)
                                <a href="#" className="ms-3" onClick={(e) => {
                                    e.preventDefault();
                                    setAllShapesShown(!allShapesShown);
                                }}>{allShapesShown ? 'Hide' : 'Show'}</a>
                            </div>
                        </div>
                    </OnMapInfoPanel>
                </>)}
                <ShapesListPanel
                    project={project}
                    visible={allShapesShown}
                    onShapeDelete={onShapeDelete}
                    onShapeActivate={onShapeActivate}
                    onShapeToggle={onShapeToggle}
                    onShapeToggleVisible={onShapeToggleVisible}
                    shapes={shapes}
                    locations={locations}
                    canEdit={canEdit}
                />


                {rulerActive ? (
                    <OnMapInfoPanel className={'style-warning'}>
                        <MapWithMeasurement />
                    </OnMapInfoPanel>
                ) : ''}
            </div>
            )}
            <div className={'map-area map-corner-top-left'}>
                <ImportExportControl
                    projectId={project.id}
                    project={project}
                    onShapesImported={onShapesImported}
                    shapes={shapes}
                    onImportFromFiles={onImportFromFiles}
                    canEdit={canEdit}
                />


                <ShapesControl
                    project={project}
                    locations={locations}
                    shapes={shapes}
                    onAddShapeClick={onAddShapeClick}
                    onShapeActiveChange={onShapeActiveChange}
                    onShapeDelete={onShapeDelete}
                    canEdit={canEdit}
                    onRulerActive={onRulerActive}
                />
            </div>
            <div className={'map-area map-corner-top-right'}>
                <MapTypeControl
                    current={selectedMapType}
                    onChange={onMapTypeControlChange}
                />

                {mapContext.isInitialized && (
                <ShapePanelControl
                    canEdit={canEdit}
                    locations={locations}
                    shapes={shapes}
                    onShapesChanged={(newShapes) => setShapes(newShapes)}
                    onShapeActiveChange={onShapeActiveChange}
                    onShapeDelete={onShapeDelete}
                    onBringToBack={onBringShapeToBack}
                    onBringToFront={onBringShapeToFront}
                />
                )}
            </div>
            <div className={'map-area map-corner-bottom-left'}>
                <OnMapInfoPanel data={`lat: ${cursorPosition.lat.toFixed(4)} lng: ${cursorPosition.lng.toFixed(4)}`} />
            </div>
            <div className={'map-area map-corner-bottom-right flex-row align-items-end'}>
                <UnitsToggle />

                <ZoomControl onZoomIn={() => {
                    map.setZoom(map.getZoom() + 1)
                }} onZoomOut={() => {
                    map.setZoom(map.getZoom() - 1)
                }} />
            </div>


            <EmailReportModal
                locations={locations}
                visible={emailReportModalShown}
                onCancel={() => {setEmailReportModalShown(false);}}
            />


            <SimpleModal
                visible={purchaseOrderModalShown}
                onClose={() => {setPurchaseOrderModalShown(false)}}
                modalContentClassName={'modal-filled'}
                headerTitle={null}
            >
                <div className="pt-3">
                    <h4 className="mt-2">Purchase Order / Reference Number</h4>
                    <p className="mt-3">Please enter your purchase order / reference number to continue:</p>

                    <input type="text" className="form-control" value={purchaseOrder} onChange={(e) => setPurchaseOrder(e.target.value)} />

                    <div className="d-flex flex-row gap-3 my-2">
                        <button
                            type="button"
                            className="btn btn-light-danger text-danger"
                            onClick={() => {setPurchaseOrderModalShown(false)}}
                        >
                            Cancel
                        </button>

                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                finalizeConfirmedProject()
                            }}
                        >
                            Confirm and Continue
                        </button>
                    </div>

                </div>
            </SimpleModal>
        </div>
    );
}