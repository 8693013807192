import React, { useState, useEffect, useRef } from 'react';
import {AsyncTypeahead, Menu, MenuItem, Typeahead} from "react-bootstrap-typeahead";
import {useApi} from "../../providers/ApiProvider";
import UsersRequests from "../../requests/UsersRequests";
import TypeaheadMenu from "react-bootstrap-typeahead/types/components/TypeaheadMenu/TypeaheadMenu";

export default function ProjectShareAutocomplete({user, onChange}) {
    const [currentUser, setCurrentUser] = useState(user);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const apiContext = useApi();
    const usersRequests = new UsersRequests(apiContext.api);


    useEffect(() => {
        setCurrentUser(user);
    }, [user]);


    const onSearch = (query) => {
        setIsLoading(true);

        usersRequests.autocomplete(query, null).then((response) => {
            if (response.length) {
                let data = response.map((user) => {
                    return {
                        value: user.id,
                        name: user.email
                    }
                });
                setOptions(data);
            } else {
                onChange(query);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    };


    const processOnChange = (selected) => {
        if (selected.length > 0) {
            setCurrentUser(selected[0]);

            onChange(selected[0].value);
        }
    }


    return (
        <div className="position-relative">
            <div className="position-absolute end-0 top-50 translate-middle-y pe-3 z-index-5"><i className="ti ti-search fs-4"></i></div>

            <AsyncTypeahead
                id={'top-search'}
                isLoading={isLoading}
                labelKey={option => `${option.name}`}
                onSearch={onSearch}
                onChange={processOnChange}
                newselectionprefix="..."
                options={options}
                className={'form-control input-onmap'}
                placeholder={currentUser.name ? currentUser.name : 'Search...'}
                emptyLabel="You're going to invite this user using their email address."
                renderMenu={(results, menuProps) => {
                    if (!isLoading && results.length === 0) {
                        return null;
                    }
                    delete menuProps.newSelectionPrefix;
                    menuProps.newselectionprefix = '...';
                    delete menuProps.paginationText;
                    delete menuProps.renderMenuItemChildren;
                    return (
                        <Menu {...menuProps}>
                            {results.map((result, index) => (
                                <MenuItem option={result} position={index} key={index}>
                                    {result.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    );
                }}
            />
        </div>
    );
}