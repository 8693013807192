import * as L from "leaflet";
import * as turf from "@turf/turf";
import "leaflet.gridlayer.googlemutant";
import proj4 from "proj4";
import "proj4leaflet";

export default class MapHelper {
    static defaultCenter = L.latLng(51.5062769,-0.1061091);
    static defaultZoomLevel = 15;
    static defaultZoomLevelDetailed = 17;
    static defaultMaxZoom = 20;
    static defaultMapType = 'googleMaps';


    static getDefaultCenter() {
        let user = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            if (user.settings && user.settings.home) {
                return L.latLng(user.settings.home[0], user.settings.home[1]);
            }
        }

        return MapHelper.defaultCenter;
    }


    static createOSMStandardLayer() {
        return L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; OpenStreetMap contributors',
            detectRetina: true,
        });
    }


    static createGoogleMapsLayer() {
        return L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution: '&copy; Google Maps'
        });
    }


    static createGoogleSatelliteLayer() {
        return L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution: '&copy; Google Maps'
        });
    }


    static createGoogleHybridLayer() {
        return L.tileLayer('https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution: '&copy; Google Maps'
        });
    }


    static createOSRoadLayer() {
        return L.tileLayer('https://api.os.uk/maps/raster/v1/zxy/Road_27700/{z}/{x}/{y}.png?key='+process.env.REACT_APP_OS_TOKEN, {
            minZoom: 10,
            maxZoom: 13,
        });
    }


    static createOSLeisureLayer() {
        return L.tileLayer('https://api.os.uk/maps/raster/v1/zxy/Leisure_27700/{z}/{x}/{y}.png?key='+process.env.REACT_APP_OS_TOKEN, {
            maxZoom: 9,
        });
    }


    static createESRIWorldImageryLayer() {
        return L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            maxZoom: 19,
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        });
    }


    static createESRITopographicLayer() {
        return L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
            maxZoom: 19,
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        });
    }


    static crs = new L.Proj.CRS('EPSG:27700', '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs', {
        resolutions: [ 896.0, 448.0, 224.0, 112.0, 56.0, 28.0, 14.0, 7.0, 3.5, 1.75, 0.875, 0.4375, 0.21875, 0.109375 ],
        origin: [ -238375.0, 1376256.0 ]
    });


    static transformCoords(arr) {
        return proj4('EPSG:27700', 'EPSG:4326', arr).reverse();
    }


    static initMap(htmlContainer, position = null, baseMapType = '') {
        let mapOptions = {
            fadeAnimation: true,
            zoomAnimation: true,
            zoomControl: false,
            doubleClickZoom: false,
            maxZoom: MapHelper.defaultMaxZoom,
        };

        if (['osRoadLayer', 'osLeisureLayer'].includes(baseMapType)) {
            mapOptions.crs = MapHelper.crs;
            mapOptions.maxBounds = [
                MapHelper.transformCoords([ -238375.0, 0.0 ]),
                MapHelper.transformCoords([ 900000.0, 1376256.0 ])
            ];
        }

        let map = L.map(htmlContainer, mapOptions);

        let OSMStandardLayer = MapHelper.createOSMStandardLayer();
        let googleMapsLayer = MapHelper.createGoogleMapsLayer();
        let googleSatelliteLayer = MapHelper.createGoogleSatelliteLayer();
        let googleHybridLayer = MapHelper.createGoogleHybridLayer();
        let esriWorldImageryLayer = MapHelper.createESRIWorldImageryLayer();
        let osRoadLayer = MapHelper.createOSRoadLayer();
        let osLeisureLayer = MapHelper.createOSLeisureLayer();
        let esriTopographicLayer = MapHelper.createESRITopographicLayer();


        if (!position) {
            position = MapHelper.getDefaultCenter();
        }
        map.setView(position, MapHelper.defaultZoomLevel);


        return {
            map: map,
            layers: {
                OSMStandard: OSMStandardLayer,
                googleMaps: googleMapsLayer,
                googleSatellite: googleSatelliteLayer,
                googleHybrid: googleHybridLayer,
                osRoadLayer: osRoadLayer,
                osLeisureLayer: osLeisureLayer,
                esriWorldImagery: esriWorldImageryLayer,
                esriTopographic: esriTopographicLayer,
            }
        };
    }


    static getUnionShape(shapes) {
        let geoJSONs = turf.polygon([]);

        Object.values(shapes).forEach((shape) => {
            if (shape.geoJSON) {
                if (shape.geoJSON.type === 'FeatureCollection') {
                    shape.geoJSON.features.forEach((feature) => {
                        geoJSONs = turf.union(feature.geometry, geoJSONs);
                    });
                } else if (shape.geoJSON.geometry && !['Point', 'LineString'].includes(shape.geoJSON.geometry.type)) {
                    //console.log(shape.geoJSON.geometry.type);
                    geoJSONs = turf.union(shape.geoJSON, geoJSONs);
                }
            }
        });


        return geoJSONs;
    }


    static coordsInShape(latLng, shape) {
        if (shape.geometry.type === 'LineString') {return false;}
        if (shape.geometry.type === 'Point') {return false;}
        let point = turf.point([latLng.lng, latLng.lat]);
        return turf.booleanPointInPolygon(point, shape);
    }


    static circleToPolygon(circle) {
        const center = circle.getLatLng();
        const radius = circle.getRadius();

        const options = {steps: 64, units: 'meters'}; // Increase steps for a smoother circle
        return turf.circle([center.lng, center.lat], radius, options);
    }


    static detectShapeType(feature) {
        if (feature.geometry.type === 'Polygon') {
            return 'Polygon';
        } else if (feature.geometry.type === 'LineString') {
            return 'Polyline';
        } else if (feature.geometry.type === 'Point') {
            return 'Marker';
        }
    }


    static getGeoJSONBounds(geoJSON) {
        const shape = L.geoJSON(geoJSON);
        return shape.getBounds();
    }


    static calculateBoundsDiameter(bounds) {
        if (!bounds || !bounds.isValid()) {return 0;}

        return bounds.getNorthEast().distanceTo(bounds.getSouthWest());
    }


    static UTCtoLocal(utcDate, format = 'DD/MM/YYYY') {
        const date = new Date(utcDate);
        const options = {};

        switch (format) {
            case 'DD/MM/YYYY':
                options.day = '2-digit';
                options.month = '2-digit';
                options.year = 'numeric';
                break;
            case 'MM/DD/YYYY':
                options.month = '2-digit';
                options.day = '2-digit';
                options.year = 'numeric';
                break;
            // Add more cases as needed
            default:
                options.day = '2-digit';
                options.month = '2-digit';
                options.year = 'numeric';
        }

        return new Intl.DateTimeFormat('default', options).format(date);
    }
}