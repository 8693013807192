import BaseCrudRequests from "./BaseCrudRequests";

/**
 * Coordinates model
 *
 * @param {ApiHelper} api
 */
export default class PropertiesRequests extends BaseCrudRequests {
    baseUrl = '/properties';

    constructor(api) {
        super(api);
    }


    async shape(data, errorHandler) {
        const url = this.baseUrl+'/shape';
        const response = await this.api.postRequest(url, data);

        return this.processResponse(response, errorHandler);
    }
}