import React, {useEffect} from 'react';

import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useApi} from "../../providers/ApiProvider";
import UsersRequests from "../../requests/UsersRequests";
import UserSearchForm from "./UserSearchForm";
import TableEditableRow from "../ui/TableEditableRow";
import {Link} from "react-router-dom";

export default function UsersList(props) {
    const apiContext = useApi();
    const usersRequests = new UsersRequests(apiContext.api);


    const fields = ['name', 'email', 'type'];
    const headerItems = usersRequests.getLabels(fields);
    const [dataItems, setDataItems] = React.useState([]);
    const [searchText, setSearchText] = React.useState(null);
    const [sorting, setSorting] = React.useState({field: 'created_at', direction: 'desc'});
    const [loading, setLoading] = React.useState(true);
    const types = {
        client: 'Client',
        admin: 'Admin',
    };


    const onSearch = (searchText) => {
        setSearchText(searchText);
    }


    const onSort = (e) => {
        let sortField = e.target.getAttribute('data-sort');
        let sortDirection = 'asc';

        if (e.target.classList.contains('asc')) {
            e.target.classList.remove('asc');
            e.target.classList.add('desc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-ascending');
                e.target.querySelector('i.ti').classList.add('ti-sort-descending');
            }

            sortDirection = 'desc';
        } else if (e.target.classList.contains('desc')) {
            e.target.classList.remove('desc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-descending');
            }

            sortField = null;
            sortDirection = null;
        } else {
            e.target.classList.add('asc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-ascending');
            }
        }

        setSorting({field: sortField, direction: sortDirection});
    }


    const onDelete = (pk) => {
        const url = '/users/' + pk + '/delete';

        usersRequests.delete(pk).then((response) => {
            if (response !== false) {
                window.location.href = '/users';
            }
        });
    }


    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let search = urlParams.get('search');
        if (search) {
            setSearchText(search);
        }

        let sort = urlParams.get('sort');
        let direction = urlParams.get('direction');
        if (sort && direction) {
            setSorting({field: sort, direction: direction});
        }

        if (!search && !sort && !direction) {
            loadData();
        }
    }, []);


    useEffect(() => {
        document.title = 'Users | Geolist';
    }, []);


    useEffect(() => {
        if (searchText !== null) {
            loadData();
        }

        updateUrl();
    }, [searchText]);


    useEffect(() => {
        loadData();

        updateUrl();
    }, [sorting]);


    const updateUrl = () => {
        let url = '/users';
        let query = {};
        if (searchText) {
            query.filter = searchText;
        }
        if (sorting.field && sorting.direction) {
            query.sort = sorting.field;
            query.direction = sorting.direction;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }
        window.history.pushState({}, '', url);
    }


    const loadData = () => {
        setLoading(true);

        usersRequests.list(fields, searchText, sorting).then((response) => {
            if (response !== false) {
                setDataItems(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    return (
        <SimpleCardContainer title={'Users'}>
            <div className="d-flex justify-content-between align-items-center pt-2 pb-4">
                <Link to="/users/create" className="btn btn-success d-flex align-items-center"><i className="ti ti-plus fs-4 me-2"></i>Create a new User</Link>
                <UserSearchForm onSearch={onSearch}/>
            </div>

            <div className="table-responsive rounded-2">
                <table className="table border text-nowrap customize-table mb-0 align-middle">
                    <thead className="text-dark fs-4">
                    <tr>
                        {headerItems.map((item, index) => {
                            return (
                                <th key={index}>
                                    <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable cursor-pointer '+((sorting.field === fields[index])?(((sorting.direction === 'desc')?(' desc'):(' asc'))):(''))} data-sort={fields[index]} onClick={onSort}>
                                        {item}
                                        {(sorting.field === fields[index]) && (sorting.direction === 'asc') && (
                                        <i className={'ti ti-sort-ascending fs-5'}></i>
                                        )}
                                        {(sorting.field === fields[index]) && (sorting.direction === 'desc') && (
                                        <i className={'ti ti-sort-descending fs-5'}></i>
                                        )}
                                    </h6>
                                </th>
                            )
                        })}
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>

                    <TableEditableRow
                        columns={headerItems.length}
                        loading={loading}
                    />
                    {dataItems.map((item, index) => {
                        return (
                            <tr key={'row-' + index}>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{types[item.type]}</td>
                                <td style={{width: '180px'}}>
                                    <div className="d-flex align-items-center justify-content-end gap-3">
                                        <Link to={'/users/' + item.id + '/edit'} className={'btn btn-light-primary btn-circle d-inline-flex align-items-center justify-content-center'}>
                                            <i className="fs-5 ti ti-edit text-primary"></i>
                                        </Link>

                                        <button type="button"
                                                className={'btn btn-light-danger btn-circle d-inline-flex align-items-center justify-content-center'}
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    if (window.confirm('Are you sure you want to delete this item?')) {
                                                        onDelete(item.id);
                                                    }
                                                }}>
                                            <i className="fs-5 ti ti-trash text-danger"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </SimpleCardContainer>
    );
}
