import * as L from "leaflet";
import ShapesModel from "./ShapesModel";
import MapHelper from "../helpers/MapHelper";

export default class PropertiesModel {
    static validateUnionShape = (unionShapeRef) => {
        if (!unionShapeRef.current) {return false;}

        if (unionShapeRef.current.bounds === {} || unionShapeRef.current.bounds && !unionShapeRef.current.bounds.isValid()) {
            return false;
        }

        if (unionShapeRef.current.shapes) {
            Object.values(unionShapeRef.current.shapes).forEach((shape) => {
                if (!ShapesModel.validateShape(shape)) {
                    return false;
                }
            });
        }

        return true;
    }


    static refreshLocations = (dryShapes, projectId, propertiesRequests) => {
        if (typeof dryShapes === 'object') {
            dryShapes = Object.values(dryShapes);
        }

        let fetchedProperties = dryShapes.map((shape) => {
            const shapeData = {
                id: shape.id,
                coordinates: shape.geoJSON.geometry.coordinates
            };

            return propertiesRequests.shape({data: shapeData, project_id: projectId}, (response) => {
                if (response && (response.message === 'Too many buildings in the selected area. Please select a smaller area.')) {
                    return {
                        error: 'Too many buildings in the selected area. Please select a smaller area.',
                        shapeId: shape.id
                    }
                } else {
                    return false;
                }
            });
        });

        return Promise.all(fetchedProperties).then((responses) => {
            // merge all responses in a single array
            let freshProperties = [],
                freshDataVersion = null,
                oversizeShapes = [];

            responses.forEach((response) => {
                if (response && response.hasOwnProperty('coordinates')) {
                    freshProperties = freshProperties.concat(response.coordinates);
                    freshDataVersion = response.dataVersion;
                } else if (response.error === 'Too many buildings in the selected area. Please select a smaller area.') {
                    oversizeShapes.push(response.shapeId);
                }
            });

            return {
                properties: freshProperties,
                dataVersion: freshDataVersion,
                oversizeShapes: oversizeShapes
            };
        });
    }


    static processProperties = (shapes, properties, locationFilters) => {
        if (typeof shapes === 'object') {
            shapes = Object.values(shapes);
        }

        let fullLocationsPerShape = {},
            positiveLocations = [],
            positiveLocationsCount = 0,
            negativeLocations = [],
            negativeLocationsCount = 0,
            positiveLocationsPerShape = {},
            negativeLocationsPerShape = {},
            totalByType = {
                'house': 0,
                'business': 0,
                'not-yet-built': 0
            };

        shapes.forEach((shape) => {
            fullLocationsPerShape[shape.id] = {
                positive: [],
                negative: [],
                counts: {
                    total: 0,
                    positive: 0,
                    negative: 0
                }
            };
            positiveLocationsPerShape[shape.id] = 0;
            negativeLocationsPerShape[shape.id] = 0;
        });

        let locationsProcessed = [],
            totalLocationsCount = 0;

        if (properties.length) {
            properties.forEach((location) => {
                let locationKeyParts = [location.lat, location.lng, location.filter], locationKey = locationKeyParts.join('|');
                let locationDuplicate = false,
                    locationFilteredOut = false,
                    locationSomewherePositive = false;

                if (locationFilters.length && !locationFilters.includes(parseInt(location.filter)) || !locationFilters.length) {
                    // filter out by location type
                    locationFilteredOut = true;
                }

                if (locationsProcessed.includes(locationKey)) {
                    // duplicated location, count only for the shape
                    locationDuplicate = true;
                    return;
                } else {
                    locationsProcessed.push(locationKey);
                }

                let shapesWithLocation = [], locationUnionType = true;

                shapes.forEach((shape) => {
                    // check if location is in this shape
                    if (shape.geoJSON && MapHelper.coordsInShape(L.latLng(location.lat, location.lng), shape.geoJSON)) {
                        shapesWithLocation.push(shape.id);

                        // check if this shape excludes this location
                        if (shape.geoJSON.properties && (shape.geoJSON.properties.union === false)) {
                            locationUnionType = false;
                        } else {
                            locationSomewherePositive = true;
                        }
                    }
                });

                let locProcessed = false;
                shapesWithLocation.forEach((key) => {
                    if (!locationSomewherePositive) {return;}

                    if (!locationFilteredOut) {
                        if (locationUnionType) {
                            positiveLocationsPerShape[key] += location.buildings_count;
                            fullLocationsPerShape[key].positive.push(location);
                            fullLocationsPerShape[key].counts.positive += location.buildings_count;
                            if (!locationDuplicate && !locProcessed) {
                                positiveLocationsCount += location.buildings_count;
                                positiveLocations.push(location);
                            }
                        } else {
                            negativeLocationsPerShape[key] += location.buildings_count;
                            fullLocationsPerShape[key].negative.push(location);
                            fullLocationsPerShape[key].counts.negative += location.buildings_count;
                            if (!locationDuplicate && !locProcessed) {
                                negativeLocationsCount += location.buildings_count;
                                negativeLocations.push(location);
                            }
                        }
                    }
                    if (!locationDuplicate && !locProcessed) {
                        totalLocationsCount += location.buildings_count;

                        if (locationUnionType && !locationFilteredOut) {
                            if (location.filter === 1) {
                                totalByType['house'] += location.buildings_count;
                            } else if (location.filter === 2) {
                                totalByType['business'] += location.buildings_count;
                            } else {
                                totalByType['not-yet-built'] += location.buildings_count;
                            }
                        }
                    }

                    if (!locationDuplicate) {
                        fullLocationsPerShape[key].counts.total += location.buildings_count;
                    }

                    locProcessed = true;
                });
            });
        }

        return {
            byShape: fullLocationsPerShape,
            positive: positiveLocations,
            negative: negativeLocations,
            stats: {
                total: totalLocationsCount,
                positive: positiveLocationsCount,
                negative: totalLocationsCount - positiveLocationsCount,
                positiveLocationsPerShape: positiveLocationsPerShape,
                negativeLocationsPerShape: negativeLocationsPerShape ? negativeLocationsPerShape : 0,
                byType: totalByType
            }
        };
    }
}