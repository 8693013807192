import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import {Navigate, useParams} from "react-router-dom";
import {useNotifications} from "../../providers/NotificationsProvider";
import ProjectsRequests from "../../requests/ProjectsRequests";
import {useAuth} from "../../providers/AuthProvider";
import UsersRequests from "../../requests/UsersRequests";

export default function ProjectMagicLink({}) {
    const {projectId, invitedHash} = useParams();
    const apiContext = useApi();
    const authContext = useAuth();
    const projectsRequests = new ProjectsRequests(apiContext.api);
    const userModel = new UsersRequests(apiContext.api);
    const notifications = useNotifications();

    const [tokens, setTokens] = React.useState({});


    useEffect(() => {

        projectsRequests.validate(projectId, invitedHash, uploadErrorHandler).then((response) => {
            if (response !== false && response.access_token) {
                let user = userModel.processAuthResponse(response);

                authContext.login(user);

                setTokens(response);
            } else {
                notifications.notify('Invalid magic link!', 'error');
                window.location.href = '/404';
            }
        });
    }, []);


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Loading error!', 'error');
        }
    }


    return tokens.access_token ? (
        <Navigate to={'/p/'+projectId} />
    ) : 'Loading...';
}
