import React, {useEffect} from 'react';

import imgProfileDefault from '../../assets/img/blank.png';
import Alert from "../ui/Alert";
import UsersRequests from "../../requests/UsersRequests";
import {useApi} from "../../providers/ApiProvider";

function ProfilePhotoCard(props) {
    const userAvatar = props.personalInfo.avatar ? props.personalInfo.avatar : imgProfileDefault;
    const apiContext = useApi();
    const userModel = new UsersRequests(apiContext.api);

    const [alertNotification, setAlertNotification] = React.useState({type: null, text: null});


    const onFileChange = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', e.target.files[0]);

        userModel.changeAvatar(formData).then((response) => {
            if (response !== false) {
                setAlertNotification({
                    type: 'success',
                    text: 'Information changed successfully.'
                });

                props.resetPersonalInfo();
            } else {
                setAlertNotification({
                    type: 'danger',
                    text: 'Information change failed.'
                });
            }
        });
    }


    const triggerFileChange = (e) => {
        e.preventDefault();

        document.querySelector('#avatar-change-file').click();
    }


    return (
        <div className="card w-100 position-relative overflow-hidden">
            <div className="card-body p-4">
                <h5 className="card-title fw-semibold">{'Change Profile'}</h5>
                <p className="card-subtitle mb-4">{'Change your profile picture from here.'}</p>

                {alertNotification.text && (
                    <Alert
                        type={alertNotification.type}
                        text={alertNotification.text}
                    />
                )}

                <div className="text-center">
                    <img src={userAvatar} alt="Profile pic" className="img-fluid rounded-circle" width="120" height="120"/>

                    <form>
                        <input type="file" className="d-none" id="avatar-change-file" accept="image/*" onChange={onFileChange} />

                        <div className="d-flex align-items-center justify-content-center my-4 gap-3">
                            <button className="btn btn-primary" onClick={triggerFileChange}>{'Upload'}</button>
                        </div>
                    </form>

                    <p className="mb-0">{'Allowed JPG, GIF or PNG. Max size of 2 MB.'}</p>
                </div>
            </div>
        </div>
    );
}

export default ProfilePhotoCard;
