import React, {useEffect} from 'react';

import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useApi} from "../../providers/ApiProvider";
import UsersRequests from "../../requests/UsersRequests";
import {useAuth} from "../../providers/AuthProvider";
import {Link, useParams} from "react-router-dom";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {useNotifications} from "../../providers/NotificationsProvider";
import ShapesRequests from "../../requests/ShapesRequests";
import FormTextArea from "../layout/ui/FormTextArea";
import FilesForm from "./FilesForm";

function FileEdit(props) {
    const breadcrumbs = useBreadcrumbs();
    const notifications = useNotifications();

    const apiContext = useApi();
    const shapesRequests = new ShapesRequests(apiContext.api);

    const {fileId} = useParams();
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState({});
    const [formData, setFormData] = React.useState({});


    useEffect(() => {

        document.title = 'Edit file information | Geolist';

        shapesRequests.view(fileId).then((response) => {
            if (response !== false) {
                setFormData({
                    name: response.name,
                    data: JSON.stringify(response.data, null, 2)
                });
            }
        }).finally(() => {
            setLoading(false);
        });
    }, []);


    const onSubmit = (inputData) => {
        inputData.data = JSON.parse(inputData.data);

        setLoading(true);
        shapesRequests.update(fileId, inputData, onError).then((response) => {
            if (response !== false) {
                notifications.notify('File information saved', 'success');

                window.location.href = '/files';
            }
        }).catch((response) => {
            onError(response);
        }).finally(() => {
            setLoading(false);
        });
    }


    const onError = (response) => {
        if (response && response.data) {
            setErrors(response.data);
        }

        setLoading(false);
        notifications.notify('Please fix errors', 'error');
    }


    return (<>
        <div className="d-flex m-block-gap">
            <Link to={'/files'} className="btn btn-light-info text-info">
                <i className="ti ti-arrow-left me-2"></i>
                Back to Files list
            </Link>
        </div>

        <SimpleCardContainer title={'Edit file information'} loading={loading}>
            <FilesForm
                formData={formData}
                className={'py-2'}
                errors={errors}
                isNewRecord={false}
                onSubmit={onSubmit}
            />
        </SimpleCardContainer>
    </>);
}

export default FileEdit;
