import React, { useState, useEffect, useRef } from 'react';
import * as L from "leaflet";
import AddressAutocomplete from "./AddressAutocomplete";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

export default function MapSearchControlGetAddress({address, onChange}) {
    const [currentAddress, setCurrentAddress] = useState(address);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [latLngActive, setLatLngActive] = useState(false);


    useEffect(() => {
        setCurrentAddress(address);
    }, [address]);


    const onSearchLocation = (query) => {
        setIsLoading(true);

        if (checkIfLatLng(query)) {
            setOptions([]);
            setLatLngActive(true);
            setIsLoading(false);
            return;
        } else if (latLngActive) {
            setLatLngActive(false);
        }

        fetch(`https://api.getAddress.io/location/${query}?api-key=${process.env.REACT_APP_GETADDRESS_TOKEN}`)
            .then(resp => resp.json())
            .then(json => {
                if (!json.suggestions || json.suggestions.length === 0) {
                    onSearchAutocomplete(query);
                } else {
                    setIsLoading(false);

                    let formattedSuggestions = [];
                    for (let suggestion of json.suggestions) {
                        formattedSuggestions.push({
                            id: suggestion.id,
                            address: suggestion.location,
                            url: suggestion.url
                        });
                    }

                    setOptions(formattedSuggestions);
                    setLatLngActive(false);
                }
            });
    };


    const onSearchAutocomplete = (query) => {
        setIsLoading(true);

        fetch(`https://api.getAddress.io/autocomplete/${query}?all=true&api-key=${process.env.REACT_APP_GETADDRESS_TOKEN}`)
            .then(resp => resp.json())
            .then(json => {
                if (json.suggestions && json.suggestions.length) {
                    setIsLoading(false);

                    setOptions(json.suggestions);
                    setLatLngActive(false);
                } else {
                    if (checkIfLatLng(query)) {
                        setLatLngActive(true);
                    }
                }
            });
    };


    const checkIfLatLng = (address) => {
        const latLngRegex = /^(-?(?:[0-8]?\d(?:\.\d+)?|90(?:\.0+)?)),\s*(-?(?:1[0-7]?\d(?:\.\d+)?|180(?:\.0+)?|\d?\d(?:\.\d+)?))$/;
        return latLngRegex.test(address);
    }


    const processOnChange = (selected) => {
        if (selected.length > 0) {
            setCurrentAddress(selected[0].address);

            if (checkIfLatLng(selected[0].address)) {
                const latLng = selected[0].address.split(',');
                onChange(latLng[0], latLng[1]);
                return;
            }

            if (!selected[0].url) {
                return;
            }

            let locationUrl = selected[0].url;
            if (locationUrl.startsWith('/')) {
                locationUrl = locationUrl.substring(1);
            }

            const url = `https://api.getAddress.io/${locationUrl}?api-key=${process.env.REACT_APP_GETADDRESS_TOKEN}`;
            fetch(url)
                .then(resp => resp.json())
                .then(json => {
                    onChange(json.latitude, json.longitude, json);
                });
        }
    }


    return (
        <div className={'flex-grow-1'}>
            <div className="position-absolute end-0 top-50 translate-middle-y pe-4 me-1"><i className="ti ti-search fs-4"></i></div>

            <AsyncTypeahead
                id={'top-search'}
                isLoading={isLoading}
                labelKey={'address'}
                onSearch={onSearchLocation}
                onChange={processOnChange}
                allowNew={latLngActive}
                newSelectionPrefix="Go to these coordinates: "

                options={options}
                filterBy={() => true}
                className={'form-control input-onmap'}
                placeholder={currentAddress ? currentAddress : 'Search address or Lat,Long...'}
            />


            {/*<AddressAutocomplete
                value={currentAddress ? currentAddress : ''}
                onChange={processAutocompleteResponse}
                types={['(regions)']}
                componentRestrictions={{ country: 'uk' }}
                className={'form-control input-onmap'}
            />*/}
        </div>
    );
}