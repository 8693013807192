import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import {useParams} from "react-router-dom";
import {useNotifications} from "../../providers/NotificationsProvider";
import ProjectsRequests from "../../requests/ProjectsRequests";
import Map from "../map/general/Map";
import {useAuth} from "../../providers/AuthProvider";

export default function ProjectCreate(props) {
    const params = useParams();
    const apiContext = useApi();
    const projectsRequests = new ProjectsRequests(apiContext.api);
    const notifications = useNotifications();
    const {user} = useAuth();


    const [data, setData] = React.useState({});


    useEffect(() => {
        if (user && user.id) {
            projectsRequests.create().then((response) => {
                if (response && response.id) {
                    setTimeout(() => {
                        window.location.href = '/p/'+response.id;
                    }, 1000);
                } else {
                    uploadErrorHandler(response);
                }
            }).catch((error) => {
                uploadErrorHandler(error.response);
            });
        }
    }, []);


    const uploadErrorHandler = (response) => {
        if (response && response.error) {
            notifications.notify('Loading error!', 'error');
        }
    }


    return (
        <div className="container">
        </div>
    );
}
