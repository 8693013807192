import React, {useEffect} from "react";
import OnMapInfoPanel from "../general/ui/OnMapInfoPanel";
import ShapeStyling from "./ShapeStyling";
import SimpleBar from "simplebar-react";


export default function ShapesListPanel({project, shapes, locations, visible, onShapeDelete, onShapeActivate, onShapeToggle, onShapeToggleVisible, canEdit}) {


    const onShow = (e, shape) => {
        e.preventDefault();

        onShapeActivate(shape.id);
    }


    const onDelete = (e, shape) => {
        e.preventDefault();

        onShapeDelete(shape.id);
    }


    const onToggle = (e, shape) => {
        e.preventDefault();

        onShapeToggle(shape.id);
    }


    const onToggleVisible = (e, shape) => {
        e.preventDefault();

        onShapeToggleVisible(shape.id);
    }



    return visible ? (<OnMapInfoPanel className={'style-warning'}>
        <h5 className="m-0">
            Shapes List
            <div className="fs-2 mt-1 text-muted">Total Shapes: {shapes ? Object.keys(shapes).length : 0}</div>
        </h5>


        <SimpleBar autoHide={true} style={{overflowX: 'hidden', maxHeight: '400px'}}>
            {shapes && Object.keys(shapes).length ? (<div className="mt-2">
                {Object.values(shapes).map((shape, index) => {
                    let totalCount = locations && locations.byShape && locations.byShape[shape.id] ? locations.byShape[shape.id].counts.total : 0;

                    let shapeType = null;
                    if (shape.type) {
                        shapeType = shape.type;
                    }
                    if (!shapeType) {
                        return;
                    }

                    let title;
                    if (shape.geoJSON && shape.geoJSON.properties && shape.geoJSON.properties.title) {
                        title = shape.geoJSON.properties.title;
                    } else {
                        title = 'Untitled Shape';
                    }

                    let color = null;
                    if (shape.geoJSON && shape.geoJSON.properties && shape.geoJSON.properties.color) {
                        color = shape.geoJSON.properties.color;
                    } else {
                        if (shape.geoJSON && shape.geoJSON.properties && shape.geoJSON.properties.union === false) {
                            color = ShapeStyling.COLOR_NEGATIVE;
                        } else {
                            color = ShapeStyling.COLOR_POSITIVE;
                        }
                    }

                    let shapeVisible = true;
                    if (shape.geoJSON && shape.geoJSON.properties && shape.geoJSON.properties.visible === false) {
                        shapeVisible = false;
                    }

                    let shapeUnion = true;
                    if (shape.geoJSON && shape.geoJSON.properties && shape.geoJSON.properties.union === false) {
                        shapeUnion = false;
                    }

                    return (
                    <div key={shape.id} className="d-flex justify-content-between align-items-center py-2 block-bg-hovered px-3" style={{margin: '0 -12px'}}>
                        <div className="d-flex align-items-center cursor-pointer" onClick={(e) => {onShow(e, shape)}}>
                            <div className="shape-color me-1" style={{backgroundColor: color, height: '7px', width: '7px', borderRadius: '7px'}}></div>
                                <div className="ms-2">
                                    <div className="d-flex flex-row align-items-center">
                                        {title} / total: {totalCount}
                                        {!shapeVisible && canEdit && (
                                            <button className={'icon-button passive'+(project.status !== '00_in_progress' ? ' d-none' : '')} onClick={(e) => {
                                                onToggleVisible(e, shape)
                                            }}>
                                                <i className={'ti ti-eye-off fs-4 ms-2'} style={{marginTop: '2px'}}></i>
                                            </button>
                                        )}
                                    </div>
                                <div className="fs-2 text-muted">Type: {shapeType}</div>
                            </div>
                        </div>
                        <div className="d-flex flex-row gap-2">
                            <button className="icon-button passive" onClick={(e) => {
                                onShow(e, shape)
                            }}><i className="ti ti-arrow-right-circle fs-4"></i></button>

                            <button className={'icon-button passive'+((project.status !== '00_in_progress' || !canEdit) ? ' d-none' : '')} onClick={(e) => {
                                onDelete(e, shape)
                            }}><i className="ti ti-trash fs-4"></i></button>

                            <button className={'icon-button passive'+((project.status !== '00_in_progress' || !canEdit) ? ' d-none' : '')} onClick={(e) => {
                                onToggle(e, shape)
                            }}>
                                <i className={'ti fs-4 ' + (shapeUnion ? 'ti-minus' : 'ti-plus')}></i>
                                <span className={'fs-2 ms-2'}>{shapeUnion ? 'Exclude' : 'Include'} Properties</span>
                            </button>
                        </div>
                    </div>);
                })}
            </div>) : null}
        </SimpleBar>
    </OnMapInfoPanel>) : null;
}