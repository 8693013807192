import * as React from "react";
import {useEffect} from "react";

const MapStructure = {
    map: null,
    isInitialized: false,
    init: () => {},
}

const MapContext = React.createContext(MapStructure);


function MapProvider({children}) {
    let [map, setMap] = React.useState(MapStructure);

    const initMap = (updatedMap) => {
        let newMap = {...map};
        newMap.map = updatedMap;

        setMap(newMap);
    };


    useEffect(() => {
        if (map.map) {
            let newMap = {...map};
            newMap.isInitialized = true;

            setMap(newMap);
        }
    }, [map.map]);


    let value = {...map};
    value.init = initMap;

    return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}


function useMap() {
    return React.useContext(MapContext);
}


export {MapProvider, useMap};