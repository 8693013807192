import {ColorPicker, ColorService, useColor} from "react-color-palette";
import "react-color-palette/css";
import chroma from "chroma-js";
import {useEffect} from "react";

import './ShapeColorPicker.css';


export default function ShapeColorPicker({visible = false, color, onChange, onChangeComplete}) {
    const minimumAlpha = 0.4;

    const [parsedColor, setParsedColor] = useColor(color);

    useEffect(() => {
        if (!color) {
            return;
        }

        let clearColor = color.replace('#', '').toLowerCase();
        let alpha = 1;
        if (clearColor.length === 8) {
            let hexAlpha = clearColor.substring(6, 8);
            clearColor = clearColor.substring(0, 6);
            alpha = parseInt(hexAlpha, 16) / 255;
        }

        const rgb = chroma(clearColor).rgb();
        if (alpha < minimumAlpha) {
            color = chroma(color).alpha(minimumAlpha).hex();
        }

        setParsedColor(ColorService.convert("rgb", {
            r: rgb[0],
            g: rgb[1],
            b: rgb[2],
            a: alpha,
        }));
    }, [color]);


    const updateColor = (color) => {
        if (color.rgb && color.rgb.a && (color.rgb.a < minimumAlpha)) {
            color.rgb.a = minimumAlpha;
            color.hsv.a = minimumAlpha;
            color.hex = chroma(color.hex).alpha(minimumAlpha).hex();
        }

        onChange(color.hex);
        setParsedColor(color);
    };


    const updateColorComplete = () => {
        onChangeComplete(parsedColor.hex);
    }


    return (
        <div className={'end-100 me-4 mt-n3 position-absolute top-0' + (visible ? '' : ' d-none')} style={{width: '300px'}}>
            <ColorPicker color={parsedColor} onChange={updateColor} hideInput={["rgb", "hsv"]} />

            <div className="shape-color-picker-submit">
                <button onClick={updateColorComplete} className="btn btn-sm btn-outline-primary w-100">Apply Colour</button>
            </div>
        </div>
    );
}