import {useEffect, useState} from "react";

export default function MarkerPopup({marker, markerLocations, dataVersion, apiContext}) {
    const [businessLocationId, setBusinessLocationId] = useState('');
    const [businessType, setBusinessType] = useState('');
    const [buildingsCount, setBuildingsCount] = useState(0);

    useEffect(() => {
        if (!markerLocations) {return;}

        const filter = markerLocations[0].filter ? markerLocations[0].filter : 1;
        let newBusinessType = 'Residential';
        if (filter === 2) {newBusinessType = 'Commercial';}
        if (filter > 2) {newBusinessType = 'Not yet built';}
        setBusinessType(newBusinessType);

        const newBuildingsCounts = markerLocations[0].buildings_count ? markerLocations[0].buildings_count : 0;
        setBuildingsCount(newBuildingsCounts);

        const newBuildingLocationId = markerLocations[0].building_location_id ? markerLocations[0].building_location_id : '';
        setBusinessLocationId(newBuildingLocationId);
    }, [markerLocations]);

    return (
        <>
            BLID: {businessLocationId}<br />
            Business Type: {businessType}<br />
            Buildings Count: {buildingsCount}
        </>
    );
}