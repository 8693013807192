import React, {useEffect} from 'react';
import {Outlet} from "react-router-dom";

import 'leaflet/dist/leaflet.css';

import imgPreloader from "modernize-essentials/k.img/preloader.png";
import {ToastContainer} from "react-toastify";
import {useGoogleMapsApi} from "./providers/googleMapsApiProvider";
import InactivityHandler from "./helpers/InactivityHandler";

export default function App(props) {
    const googleMapsApiContext = useGoogleMapsApi();
    const [preloaderActive, setPreloaderActive] = React.useState(true);

    useEffect(() => {
        setPreloaderActive(false);

        includeGoogleMapsAPI();
    }, []);


    const includeGoogleMapsAPI = () => {
        const script = document.createElement("script");

        const params = {
            v: '3.exp',
            libraries: 'places',
            callback: 'googleMapsAPILoadedCallback',
            key: process.env.REACT_APP_GOOGLEMAPS_TOKEN
        };
        let paramsArr = [];
        for (const [key, value] of Object.entries(params)) {paramsArr.push(key+'='+value);}
        script.src = "https://maps.googleapis.com/maps/api/js?"+paramsArr.join('&');
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);

        if (window.googleMapsLoaded) {
            googleMapsApiContext.update(true);
        } else {
            window.addEventListener('google-maps-loaded', () => {
                googleMapsApiContext.update(true);
            });
        }
    }


    return (<>
        <div className={'preloader'+(!preloaderActive ? ' hide' : '')}>
            <img src={imgPreloader} alt="loader" className="lds-ripple img-fluid"/>
        </div>

        <div className="page-wrapper" id="main-wrapper" data-layout="horizontal" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
            <Outlet/>
        </div>

        <InactivityHandler />
        <ToastContainer />
    </>);
}
