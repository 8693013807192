import React, {useEffect} from 'react';

import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useApi} from "../../providers/ApiProvider";
import UsersRequests from "../../requests/UsersRequests";
import {useAuth} from "../../providers/AuthProvider";
import {useParams} from "react-router-dom";
import {useNotifications} from "../../providers/NotificationsProvider";

export default function UserCreate(props) {
    const params = useParams();
    const authContext = useAuth();
    const userData = authContext.user;
    const apiContext = useApi();
    const usersRequests = new UsersRequests(apiContext.api);
    const notifications = useNotifications();

    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [repassword, setRepassword] = React.useState('');
    const [type, setType] = React.useState('client');
    const [name, setName] = React.useState('');
    const types = {
        client: 'Client',
        admin: 'Admin',
    };


    useEffect(() => {

        document.title = 'Create a new user | Geolist';
    }, []);


    const onSubmit = (e) => {
        e.preventDefault();

        setLoading(true);
        usersRequests.create({
            email: email,
            password: password,
            repassword: repassword,
            type: type,
            name: name,
        }, onSubmitError).then((response) => {
            if (response !== false) {
                window.location.href = '/users/'+response.id+'/edit';
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const onSubmitError = (response) => {
        if (response.data) {
            let errors = {};
            Object.keys(response.data).map((field) => {
                errors[field] = response.data[field].join(' ');
            });

            setErrors(errors);
            notifications.notify('Please correct the errors before continuing.', 'error');
        }
    }


    return (
        <SimpleCardContainer title={'Create a new user'} loading={loading}>
            <form className={'py-2'}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <div className={'row'}>
                            <div className={'col-lg-6'}>
                                <div className={'form-group'}>
                                    <label className="form-label fw-semibold mb-2" htmlFor="cd-email">{'E-mail'}</label>

                                    <input type="email" id="cd-email" autoComplete={'off'} className={'form-control'} value={email} onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}/>

                                    {errors['email'] && <div className="form-text text-danger">{errors['email']}</div>}
                                </div>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-lg-6'}>
                                <div className={'form-group mt-3'}>
                                    <label className="form-label fw-semibold mb-2" htmlFor="cd-password">{'Password'}</label>

                                    <input type="password" id="cd-password" autoComplete={'off'} className={'form-control'} value={password} onChange={(e) => {
                                        setPassword(e.target.value)
                                    }}/>

                                    {errors['password'] && <div className="form-text text-danger">{errors['password']}</div>}
                                </div>
                            </div>
                            <div className={'col-lg-6'}>
                                <div className={'form-group mt-3'}>
                                    <label className="form-label fw-semibold mb-2" htmlFor="cd-repassword">{'Repeat password'}</label>

                                    <input type="password" id="cd-repassword" autoComplete={'off'} className={'form-control'} value={repassword} onChange={(e) => {
                                        setRepassword(e.target.value)
                                    }}/>

                                    {errors['repassword'] && <div className="form-text text-danger">{errors['repassword']}</div>}
                                </div>
                            </div>
                        </div>

                        <div className={'form-group mt-3'}>
                            <label className="form-label fw-semibold mb-2">{'Full name'}</label>

                            <input id={'cd-name'} className={'form-control'} value={name} onChange={(e) => {
                                setName(e.target.value)
                            }}/>

                            {errors['name'] && <div className="form-text text-danger">{errors['name']}</div>}
                        </div>

                        <div className={'form-group mt-3'}>
                            <label className="form-label fw-semibold mb-2">{'Role'}</label>

                            <select id={'cd-button'} className={'form-control form-select'} value={type} onChange={(e) => {
                                setType(e.target.value)
                            }}>
                                {Object.keys(types).map((key) => {
                                    return <option value={key} key={key}>{types[key]}</option>
                                })}
                            </select>

                            {errors['type'] && <div className="form-text text-danger">{errors['type']}</div>}

                            <button className="btn btn-primary mt-3" onClick={onSubmit}>{'Save changes'}</button>
                        </div>
                    </div>
                </div>
            </form>
        </SimpleCardContainer>
    );
}
