import * as L from "leaflet";
import MapHelper from "../helpers/MapHelper";
import ProjectsRequests from "../requests/ProjectsRequests";
import _ from 'lodash';


export default class ShapesModel {
    static MAX_SHAPE_DIAMETER = 50000; // in meters

    projectsRequests = null;
    dryShapes = {};
    oversizeShapes = [];
    invalidShapes = [];

    bounds = null;
    boundsValid = false;

    locationStats = {
        total: 0,
        positive: 0,
        negative: 0
    };

    constructor(projectsRequests: ProjectsRequests) {
        this.projectsRequests = projectsRequests;
    }


    update(shapes, locationStats = null) {
        this.updateLocations(locationStats);

        const dryingResults = ShapesModel.dryShapes(shapes);
        if (
            _.isEqual(dryingResults.dryShapes, this.dryShapes) &&
            _.isEqual(dryingResults.oversizeShapes, this.oversizeShapes) &&
            _.isEqual(dryingResults.invalidShapes, this.invalidShapes)
        ) {
            return;
        }

        this.dryShapes = dryingResults.dryShapes;
        this.oversizeShapes = dryingResults.oversizeShapes;
        this.invalidShapes = dryingResults.invalidShapes;

        // build bounds for all shapes together
        this.bounds = null;
        Object.keys(this.dryShapes).forEach((shapeId) => {
            const shape = this.dryShapes[shapeId];
            if (shape.bounds) {
                if (this.bounds === null) {
                    this.bounds = L.latLngBounds(shape.bounds._northEast, shape.bounds._southWest);
                }
                this.bounds = L.latLngBounds(this.bounds).extend([shape.bounds._northEast, shape.bounds._southWest]);
            }
        });
        this.boundsValid = this.bounds && (typeof this.bounds === 'object') && this.bounds.isValid();
    }


    updateLocations(locationStats) {
        if (typeof locationStats !== 'object' || !locationStats) {return;}

        Object.keys(this.locationStats).forEach((key) => {
            if (locationStats[key]) {
                this.locationStats[key] = locationStats[key];
            } else {
                this.locationStats[key] = 0;
            }
        });
    }


    static validateShape = (shape) => {
        if (!(shape.geoJSON && shape.geoJSON.geometry && shape.geoJSON.geometry.coordinates)) {return false;}

        let shapeBounds;
        try {
            shapeBounds = L.geoJSON(shape.geoJSON).getBounds();
        } catch (error) {
            return false;
        }

        return shapeBounds.isValid();
    }


    static validateShapeDiameter = (shapeBounds) => {
        const diameter = MapHelper.calculateBoundsDiameter(shapeBounds);

        return diameter <= ShapesModel.MAX_SHAPE_DIAMETER;
    }


    static _dryShape = (shape) => {
        if (!ShapesModel.validateShape(shape)) {return;}

        return {
            id: shape.id,
            type: shape.type,
            geoJSON: shape.geoJSON,
            layerData: shape.layerData,
            bounds: shape.bounds
        };
    }


    static dryShapes = (shapes) => {
        if (!shapes) {
            return {
                dryShapes: {},
                oversizeShapes: [],
                invalidShapes: []
            };
        }

        if (typeof shapes === 'object') {
            shapes = Object.values(shapes);
        }

        let dryShapes = {}, dryShapeTmp,
            oversizeShapes = [],
            invalidShapes = [];
        if (shapes.length) {
            shapes.forEach((shape) => {
                if (!ShapesModel.validateShapeDiameter(L.geoJSON(shape.geoJSON).getBounds())) {
                    oversizeShapes.push(shape);
                    return;
                }

                dryShapeTmp = ShapesModel._dryShape(shape);
                if (dryShapeTmp) {
                    dryShapes[shape.id] = dryShapeTmp;
                } else {
                    invalidShapes.push(shape);
                }
            });
        }

        return {
            dryShapes: dryShapes,
            oversizeShapes: oversizeShapes,
            invalidShapes: invalidShapes
        };
    }


    getDataForProjectUpdate = () => {
        const packedData = {
            shapes: this.dryShapes,
            bounds: this.bounds,
            locations: this.locationStats,
        };

        return JSON.parse(JSON.stringify(packedData));
    }
}