import React from 'react';

export default function Pagination({pagination, onPageChange}) {
    const {current_page, last_page, per_page, total} = pagination;

    const getPageNumbers = () => {
        const delta = 2;
        const range = [];
        const rangeWithDots = [];

        // Always show first page
        range.push(1);

        for (let i = current_page - delta; i <= current_page + delta; i++) {
            if (i > 1 && i < last_page) {
                range.push(i);
            }
        }

        // Always show last page
        if (last_page !== 1) {
            range.push(last_page);
        }

        // Add dots between numbers if needed
        let l;
        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    };

    return current_page ? (
        <nav aria-label="Page navigation">
            <ul className="pagination">
                {/* Previous button */}
                <li className={`page-item ${current_page === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => onPageChange(current_page - 1)}
                        disabled={current_page === 1}
                    >
                        Previous
                    </button>
                </li>

                {/* Page numbers */}
                {getPageNumbers().map((page, index) => (
                    <li
                        key={index}
                        className={`page-item ${page === current_page ? 'active' : ''} ${
                            page === '...' ? 'disabled' : ''
                        }`}
                    >
                        <button
                            className="page-link"
                            onClick={() => (page !== '...' ? onPageChange(page) : null)}
                            disabled={page === '...'}
                        >
                            {page}
                        </button>
                    </li>
                ))}

                {/* Next button */}
                <li className={`page-item ${current_page === last_page ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => onPageChange(current_page + 1)}
                        disabled={current_page === last_page}
                    >
                        Next
                    </button>
                </li>
            </ul>
        </nav>
    ) : '';
};