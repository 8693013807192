import {jwtDecode} from "jwt-decode";
import {userStructure} from "../providers/AuthProvider";

/**
 * Users model
 *
 * @param {ApiHelper} api
 */
class UsersRequests {
    constructor(api) {
        this.api = api;
    }


    async changePassword(oldPassword, newPassword) {
        const url = '/users/update-password';
        const data = {
            old_password: oldPassword,
            new_password: newPassword
        };

        const response = await this.api.postRequest(url, data);
        return (response.data.success === true)?(response.data):false;
    }


    async changeAvatar(formData) {
        const url = '/users/update-avatar';

        const response = await this.api.postRequestFile(url, formData);
        return (response.data.success === true)?(response.data):false;
    }


    async getPersonalInfo() {
        const url = '/users/get-personal-info';

        const response = await this.api.getRequest(url);
        return (response.data.success === true)?(response.data):false;
    }


    async changePersonalInfo(newPersonalInfo) {
        const url = '/users/update-personal-info';

        const response = await this.api.postRequest(url, newPersonalInfo);
        return (response.data.success === true)?(response.data):false;
    }


    getLabels(fields) {
        const labels = {
            name: 'Name',
            email: 'Email',
            type: 'Type',
            businesses_count: 'Businesses'
        };

        let results = [];
        for (let i = 0; i < fields.length; i++) {
            results.push(labels[fields[i]]);
        }

        return results;
    }


    async list(fields, filter = null, sorting =  null, errorHandler) {
        let url = '/users';
        let query = {};
        if (filter) {
            query.filter = filter;
        }
        if (sorting) {
            query.sort = sorting.field;
            query.direction = sorting.direction;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }

        const response = await this.api.getRequest(url);
        return this.processResponse(response, errorHandler);
    }


    /**
     * Autocomplete
     *
     * @param {string} query
     * @param errorHandler
     * @returns {Promise}
     */
    async autocomplete(query, errorHandler) {
        const url = '/users/autocomplete/' + query;

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async view(pk, errorHandler) {
        const url = '/users/' + pk;

        const response = await this.api.getRequest(url);
        return this.processResponse(response, errorHandler);
    }


    async create(data, errorHandler) {
        const url = '/users';

        const response = await this.api.postRequest(url, data);
        return this.processResponse(response, errorHandler);
    }


    async update(pk, data, errorHandler) {
        const url = '/users/' + pk;

        const response = await this.api.postRequest(url, data);
        return this.processResponse(response, errorHandler);
    }


    async delete(pk, errorHandler) {
        const url = '/users/' + pk + '/delete';

        const response = await this.api.deleteRequest(url);
        return this.processResponse(response, errorHandler);
    }


    async impersonate(pk, errorHandler) {
        const url = '/users/' + pk + '/impersonate';

        const response = await this.api.postRequest(url);
        return this.processResponse(response, errorHandler);
    }


    processAuthResponse(response) {
        if (!response.access_token) {return false;}

        const decodedAccessToken = jwtDecode(response.access_token);
        let decodedRefreshToken = null;
        if (response.refresh_token) {
            decodedRefreshToken = jwtDecode(response.refresh_token);
        }

        let user = JSON.parse(JSON.stringify(userStructure));
        user.id = decodedAccessToken.user_id;
        user.access_token = response.access_token;
        user.access_token_expires_at = decodedAccessToken.exp;
        user.refresh_token = response.refresh_token;
        user.refresh_token_expires_at = (decodedRefreshToken)?(decodedRefreshToken.exp):null;

        Object.keys(decodedAccessToken).forEach((key) => {
            if (['user_id', 'token'].indexOf(key) === -1) {
                user[key] = decodedAccessToken[key];
            }
        });

        return user;
    }


    processResponse(response, errorHandler) {
        if (response.status.toString().startsWith('20') && response.data && response.data.success === true && response.data.data) {
            return response.data.data;
        } else {
            if (errorHandler) {
                errorHandler(response.data);
            }
        }

        return false;
    }
}

export default UsersRequests;