import React, {useEffect} from 'react';
import {useNotifications} from "../../../../providers/NotificationsProvider";
import SimpleModal from "../../../ui/SimpleModal";
import ButtonWithLoading from "../../../layout/ui/ButtonWithLoading";
import ProjectShareAutocomplete from "../../../projects/ProjectShareAutocomplete";
import {useApi} from "../../../../providers/ApiProvider";
import ShapesRequests from "../../../../requests/ShapesRequests";
import SimpleBar from 'simplebar-react';

import 'simplebar-react/dist/simplebar.min.css';

export default function ImportFromFilesModal({visible = false, onClick, onCancel}) {
    const notifications = useNotifications();
    const apiContext = useApi();
    const shapesRequests = new ShapesRequests(apiContext.api);

    const [modalLoading, setModalLoading] = React.useState(false);
    const [files, setFiles] = React.useState([]);
    const [sort, setSort] = React.useState({field: 'name', direction: 'asc'});


    useEffect(() => {
        if (!visible) {return;}

        setModalLoading(true);
        shapesRequests.list({perPage: 100}).then((response) => {
            if (response !== false && response.data) {
                setFiles(response.data);
            }
        }).finally(() => {
            setModalLoading(false);
        });
    }, [visible]);


    useEffect(() => {
        setFiles([...files].sort((a, b) => {
            if (sort.direction === 'asc') {
                return a[sort.field] > b[sort.field] ? 1 : -1;
            } else {
                return a[sort.field] < b[sort.field] ? 1 : -1;
            }
        }));
    }, [sort]);


    const sortFiles = (field) => {
        if (sort.field === field) {
            setSort({field: field, direction: sort.direction === 'asc' ? 'desc' : 'asc'});
        } else {
            setSort({field: field, direction: 'asc'});
        }
    }


    return (
        <SimpleModal visible={visible} onClose={onCancel} loading={modalLoading} headerTitle="Import from My Files">
            {files && files.length ? (
                <div className="mb-4">
                    <h4 className="text-uppercase fs-2 fw-semibold mb-2 pb-1">Select a File</h4>

                    <SimpleBar style={{ maxHeight: 500 }}>
                        <table className="table table-sm table-striped table-hover fs-2">
                            <thead>
                            <tr>
                                <th className="cursor-pointer" onClick={() => sortFiles('name')}>File Name</th>
                                <th className="cursor-pointer" onClick={() => sortFiles('updated_at')}>Updated At</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {files.map((file, index) => (
                                <tr key={index}>
                                    <td className="align-content-center">{file.name}</td>
                                    <td className="align-content-center">{(new Date(file.updated_at)).toLocaleString()}</td>
                                    <td>
                                        <ButtonWithLoading
                                            className="btn-circle btn-primary btn-sm justify-content-center"
                                            iconClassName="ti ti-plus fs-3"
                                            noOriginalIconClassName={true}
                                            loading={modalLoading}
                                            spinnerClassName="spinner-border spinner-border-sm"
                                            noOriginalSpinnerClassName={true}
                                            onClick={() => {
                                                onClick(file.id)
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </SimpleBar>
                </div>
            ) : (<div className="text-muted fs-3">No files available.</div>)}
        </SimpleModal>
    );
}
