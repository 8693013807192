import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import {useParams} from "react-router-dom";
import {useNotifications} from "../../providers/NotificationsProvider";
import ProjectsRequests from "../../requests/ProjectsRequests";
import Map from "../map/general/Map";
import {useAuth} from "../../providers/AuthProvider";
import Loading from "../ui/Loading";
import LoadingBig from "../ui/loaders/LoadingBig";
import _ from "lodash";

export default function ProjectView({}) {
    const {user, setInactivityActivated} = useAuth();
    const {projectId, accessToken} = useParams();
    const apiContext = useApi();
    const projectsRequests = new ProjectsRequests(apiContext.api);
    const notifications = useNotifications();

    const [loading, setLoading] = React.useState('');
    const [project, setProject] = React.useState({});
    const [canEdit, setCanEdit] = React.useState(false);


    useEffect(() => {
        let viewParams = {
            pk: projectId
        };
        if (accessToken) {
            viewParams.ua = accessToken;
        }

        projectsRequests.view(viewParams, uploadErrorHandler).then((response) => {
            if (response) {
                setProject(response);
                document.title = response.title+' | GeoList';

                setLoading('dataFetched');
            }
        });
    }, []);


    useEffect(() => {
        const newCanEdit = userCanEdit();
        if (newCanEdit !== canEdit) {
            setCanEdit(newCanEdit);
        }


        if (project && (project.access_level === 'public')) {
            setInactivityActivated(false);
        }
    }, [project]);


    const uploadErrorHandler = (response) => {
        if (response && response.data === 'Invalid access token') {
            setLoading('error-invalid-access-token');
        } else {
            setLoading('error');
        }
    }


    const userCanEdit = () => {
        if (!user || !project || (project.status !== '00_in_progress')) {
            return false;
        }

        if (project.user_id === user.id || user.type === 'admin') {
            return true;
        }

        if (project.shares) {
            let share = project.shares.find((share) => share.invited_id === user.id);
            if (share && share.level === 'edit') {
                return true;
            }
        }

        return false;
    }


    const onProjectChange = (newProjectData, propagateChanges = true) => {
        let projectUpdatedPromise;
        if (accessToken) {
            projectUpdatedPromise = projectsRequests.uaUpdate(accessToken, projectId, newProjectData);
        } else {
            projectUpdatedPromise = projectsRequests.update(projectId, newProjectData);
        }

        return projectUpdatedPromise.then((response) => {
            if (response.id) {
                const projectChanged = _.isEqual(project,response);
                if (projectChanged && propagateChanges) {
                    setProject(response);
                }
            }

            return response;
        });
    }


    const onMapInitialized = () => {
        setLoading('mapInitialized');
    }


    return (<>
        {!['error', 'error-invalid-access-token', 'mapInitialized'].includes(loading) ? (
            <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <LoadingBig show={true} />
            </div>
        ) : ''}

        {loading === 'error' ? (
            <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                <h1>Oops! Something Went Wrong</h1>
                <p>We encountered an unexpected issue.</p>
                <p>Please let us know what happened by emailing <a href="mailto:team@geolist.co.uk">team@geolist.co.uk</a>. If possible, include details about what you were doing when the error occurred.</p>
            </div>
        ) : ''}

        {loading === 'error-invalid-access-token' ? (
            <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                <h1>Invalid Share Link</h1>
                <p>The sharing link you used is no longer valid.</p>
                <p>It’s possible that access has been removed. If you believe this is a mistake, please contact the owner of the shared project or email us at <a href="mailto:team@geolist.co.uk">team@geolist.co.uk</a> for assistance.</p>
            </div>
        ) : ''}

        {['dataFetched', 'mapInitialized'].includes(loading) ? (
            <Map
                project={project}
                canEdit={canEdit}
                onProjectChange={onProjectChange}
                onMapInitialized={onMapInitialized}
            />
        ) : ''}
    </>);
}
