import BaseCrudRequests from "./BaseCrudRequests";

/**
 * ProjectShares model
 *
 * @param {ApiHelper} api
 */
export default class ProjectSharesRequests extends BaseCrudRequests {
    baseUrl = '/project-shares';

    constructor(api) {
        super(api);
    }
}