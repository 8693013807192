import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import Home from "./components/common/Home";
import Auth from "./components/auth/Auth";
import Login from "./components/auth/Login";
import React from "react";
import ForgotPassword from "./components/auth/ForgotPassword";
import Error404 from "./components/errors/Error404";
import Logout from "./components/auth/Logout";
import MainLayout from "./components/layout/MainLayout";
import ForgotPasswordConfirm from "./components/auth/ForgotPasswordConfirm";
import Register from "./components/auth/Register";
import Projects from "./components/projects/Projects";
import ProjectView from "./components/projects/ProjectView";
import ProjectsList from "./components/projects/ProjectsList";
import {RequireAuth} from "./providers/AuthProvider";
import Users from "./components/users/Users";
import UserEdit from "./components/users/UserEdit";
import UsersList from "./components/users/UsersList";
import UserCreate from "./components/users/UserCreate";
import ProjectSharesList from "./components/projectShares/ProjectSharesList";
import ProjectCreate from "./components/projects/ProjectCreate";
import Files from "./components/files/Files";
import FilesList from "./components/files/FilesList";
import FileEdit from "./components/files/FileEdit";
import FileCreate from "./components/files/FileCreate";
import AccountInfoTab from "./components/account/AccountInfoTab";
import AdminProjectsList from "./components/adminProjects/AdminProjectsList";
import AdminProjects from "./components/adminProjects/AdminProjects";
import AdminProjectEdit from "./components/adminProjects/AdminProjectEdit";
import ProjectMagicLink from "./components/projects/ProjectMagicLink";

export const router = createBrowserRouter([
    {
        path: "/",
        Component: App,
        children: [
            {
                index: true,
                element: (<RequireAuth><MainLayout withMap={true}><Home/></MainLayout></RequireAuth>),
            },
            {
                path: "auth",
                Component: Auth,
                children: [
                    {
                        path: "login",
                        Component: Login,
                    },
                    {
                        path: "register",
                        Component: Register,
                    },
                    {
                        path: "forgot-password",
                        children: [
                            {
                                path: "confirm",
                                Component: ForgotPasswordConfirm,
                            },
                            {
                                index: true,
                                Component: ForgotPassword,
                            }
                        ],
                    },
                    {
                        path: "logout",
                        Component: Logout,
                    },
                ],
            },
            {
                path: "p",
                element: (<Projects />),
                children: [
                    {
                        path: "shared",
                        element: (<RequireAuth><MainLayout withMap={false}><ProjectSharesList/></MainLayout></RequireAuth>),
                    },
                    {
                        path: "create",
                        element: (<ProjectCreate/>),
                    },
                    {
                        path: ":projectId/ml/:invitedHash",
                        element: (<MainLayout withMap={true}><ProjectMagicLink/></MainLayout>),
                    },
                    {
                        path: ":projectId/ua/:accessToken",
                        element: (<MainLayout withMap={true}><ProjectView/></MainLayout>),
                    },
                    {
                        path: ":projectId",
                        element: (<MainLayout withMap={true}><ProjectView/></MainLayout>),
                    },
                    {
                        index: true,
                        element: (<RequireAuth><MainLayout withMap={false}><ProjectsList/></MainLayout></RequireAuth>),
                    }
                ],
            },
            {
                path: "settings",
                element: (<RequireAuth><MainLayout withMap={false}><AccountInfoTab /></MainLayout></RequireAuth>),
            },
            {
                path: "files",
                element: (<RequireAuth><MainLayout withMap={false}><Files /></MainLayout></RequireAuth>),
                children: [
                    {
                        path: "create",
                        element: (<FileCreate/>),
                    },
                    {
                        path: ":fileId",
                        element: (<FileEdit />),
                    },
                    {
                        index: true,
                        element: (<FilesList/>),
                    }
                ],
            },
            {
                path: "users",
                element: (<RequireAuth><Users /></RequireAuth>),
                children: [
                    {
                        path: "create",
                        Component: UserCreate,
                    },
                    {
                        path: ":userId/edit",
                        Component: UserEdit,
                    },
                    {
                        index: true,
                        Component: UsersList,
                    },
                ],
            },
            {
                path: "projects",
                element: (<RequireAuth><AdminProjects /></RequireAuth>),
                children: [
                    /*{
                        path: "create",
                        Component: UserCreate,
                    },*/
                    {
                        path: ":projectId",
                        Component: AdminProjectEdit,
                    },
                    {
                        index: true,
                        Component: AdminProjectsList,
                    },
                ],
            },
        ],
    },
    {
        path: "*",
        Component: Error404,
    }
]);
