import React, {useCallback, useEffect, useState} from 'react';

import Shape from "../../shapes/Shape";
import ShapeStyling from "../../shapes/ShapeStyling";

import Locations from "../../locations/Locations";
import {useParams} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import {useAuth} from "../../../../providers/AuthProvider";


export default function ShapesControl({ project, locations, shapes, onAddShapeClick, onRulerActive, canEdit = false}) {
    const {projectId} = useParams();
    const {user} = useAuth();

    const [rulerActive, setRulerActive] = useState(false);
    const [btnActive, setBtnActive] = useState('');
    const [projectStatus, setProjectStatus] = useState(project.status ? project.status : '30_cancelled');


    useEffect(() => {
        if (project.status) {
            setProjectStatus(project.status);
        }
    }, [project.status]);


    useEffect(() => {
        let shapesActive = true;
        if (shapes && Object.keys(shapes).length) {
            Object.values(shapes).forEach((shape) => {
                if (!shape.active) {
                    shapesActive = false;
                }
            });
        }

        if (!shapesActive && btnActive) {
            setBtnActive('');
        }
    }, [shapes]);


    const onRulerClick = (e) => {
        e.preventDefault();
        if (!canEdit) {return false;}

        setRulerActive(!rulerActive);

        onRulerActive(rulerActive);
    }


    const onAddClick = (e, type) => {
        e.preventDefault();
        if (!canEdit) {return false;}

        if (btnActive === 'new-shape-' + type) {
            setBtnActive('');
            onAddShapeClick(null);
        } else {
            setBtnActive('new-shape-' + type);
            onAddShapeClick(type);
        }
    }


    const onDownloadCatids = (e) => {
        e.preventDefault();
        if (!canEdit) {return false;}

        let catids = [];
        Object.keys(locations.byShape).forEach((key) => {
            locations.byShape[key].positive.forEach((location) => {
                if (!catids.length) {
                    //let headers = ['ProjectId'].concat(Object.keys(location));
                    const headers = ['Project_ID', 'User_ID', 'Building_location_ID', 'Filter', 'Version', 'Requested'];
                    catids.push(headers);
                }

                // format as YYYY-MM-DD HH:MM:SS
                let currentDateUTC = new Date().toISOString().slice(0, 19).replace('T', ' ');
                let row = [projectId, user.id, location.building_location_id, location.filter, location.version, currentDateUTC];
                catids.push(row);
            });
        });

        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += catids.map(e => e.join(",")).join("\n");

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "catids-"+projectId+".csv");
        document.body.appendChild(link); // Required for FF

        link.click();

        document.body.removeChild(link);
    }




    return (
        <>
            <div className={'btn-group-vertical'+((projectStatus !== '00_in_progress' || !canEdit) ? ' d-none' : '')}>
                <button
                    onClick={(e) => onAddClick(e, ShapeStyling.TYPE_POLYGON)}
                    className={'btn btn-onmap' + (btnActive === 'new-shape-' + ShapeStyling.TYPE_POLYGON ? ' active' : '')}

                    data-tooltip-content="Draw a polygon Shape"
                    data-tooltip-place="right"
                    data-tooltip-id="shapes-control-tooltip"
                >
                    <i className={'ti ti-polygon fs-5'}></i>
                </button>
                <button
                    onClick={(e) => onAddClick(e, ShapeStyling.TYPE_RECTANGLE)}
                    className={'btn btn-onmap' + (btnActive === 'new-shape-' + ShapeStyling.TYPE_RECTANGLE ? ' active' : '')}

                    data-tooltip-content="Draw a Rectangle"
                    data-tooltip-place="right"
                    data-tooltip-id="shapes-control-tooltip"
                >
                    <i className={'ti ti-rectangle fs-5'}></i>
                </button>
                <button
                    onClick={(e) => onAddClick(e, ShapeStyling.TYPE_CIRCLE)}
                    className={'btn btn-onmap' + (btnActive === 'new-shape-' + ShapeStyling.TYPE_CIRCLE ? ' active' : '')}

                    data-tooltip-content="Draw a radius"
                    data-tooltip-place="right"
                    data-tooltip-id="shapes-control-tooltip"
                >
                    <i className={'ti ti-circle-dot fs-5'}></i>
                </button>
                <button
                    onClick={(e) => onAddClick(e, ShapeStyling.TYPE_POINT)}
                    className={'btn btn-onmap' + (btnActive === 'new-shape-' + ShapeStyling.TYPE_POINT ? ' active' : '')}

                    data-tooltip-content="Place a Point"
                    data-tooltip-place="right"
                    data-tooltip-id="shapes-control-tooltip"
                >
                    <i className={'ti ti-map-pin fs-5'}></i>
                </button>
                <button
                    onClick={(e) => onAddClick(e, ShapeStyling.TYPE_POLYLINE)}
                    className={'btn d-none btn-onmap' + (btnActive === 'new-shape-' + ShapeStyling.TYPE_POLYLINE ? ' active' : '')}

                    data-tooltip-content="Buffer tool"
                    data-tooltip-place="right"
                    data-tooltip-id="shapes-control-tooltip"
                >
                    <i className={'ti ti-box-padding fs-5'}></i>
                </button>
            </div>

            <div className="btn-group-vertical">
                <button
                    onClick={onRulerClick}
                    className={'btn btn-onmap'}

                    data-tooltip-content="Measure distance"
                    data-tooltip-place="right"
                    data-tooltip-id="shapes-control-tooltip"
                >
                    <i className={'ti ti-ruler fs-5'}></i>
                </button>
            </div>

            {/*shapes && Object.keys(shapes).length ? (
                <div className={'btn-group-vertical'+((projectStatus !== '00_in_progress' || !canEdit) ? ' d-none' : '')}>
                    <button
                        onClick={onRefreshClick}
                        className={'btn btn-onmap'}

                        data-tooltip-content="Generate Counts"
                        data-tooltip-place="right"
                        data-tooltip-id="shapes-control-tooltip"
                    >
                        <i className={'ti ti-refresh-dot fs-5'}></i>
                    </button>
                </div>
            ) : ''*/}

            <div className={'btn-group-vertical'+(user.type !== 'admin' ? ' d-none' : '')}>
                <button
                    onClick={onDownloadCatids}
                    className={'btn btn-onmap'}

                    data-tooltip-content="Export Data"
                    data-tooltip-place="right"
                    data-tooltip-id="shapes-control-tooltip"
                >
                    <i className={'ti ti-table-down fs-5'}></i>
                </button>
            </div>

            <Tooltip id="shapes-control-tooltip" />
        </>
    );
}